$body-background: #fafafa;
$body-color: #444;

@font-face {
  font-family: 'Kisba';
  src: url('../fonts/Kisba-Regular/font.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Kisba-Regular/font.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'Flink';
  src: url('../fonts/Flink/normal_normal_normal.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Flink/normal_normal_normal.woff') format('woff'); /* Pretty Modern Browsers */
}

body {
  background-color: #4a525a;
  // max-width: 60rem;
  margin: 0 2rem;
  color: #eee;
}

main {
  margin: 4rem auto;
  max-width: 45rem;
}

#header {
  margin-top: 50px;
}

#header img {
  max-width: 100%;
}

p {
  margin-left: 2em;
}

h2 {
  font-family: 'Kisba', serif;
  font-size: 1.35em;
}

h3, p {
  font-family: 'Flink', sans-serif;
}

h3 {
  font-size: 1.25em;
}

h1, nav, footer {
  text-align: center;
}
